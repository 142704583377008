import React from 'react'

export default function packages() {
  return (
    <div className='pagepackages' style={styles.container}>
      {/* <GradientText as='h2' /> */}
    </div>
  )
}

const styles = {
  container: {
    height: '200vh',
    display: 'flex',
    flexDirection: 'column',
  },
}
